import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import { RelatedArticles } from '../../components/RelatedArticles'
import he from 'he'

import SEO from '../../components/seo'
import { OperatorDetailsPage } from '../../components/OperatorDetailsPage'

class OperatorTemplate extends Component {
  render() {
    let post

    post = this.props.data.operator

    const addressString = `${
      post.avContactInfo.address ? post.avContactInfo.address + ', ' : ''
    }${post.avContactInfo.suburb}, VIC ${post.avContactInfo.postcode}`

    return (
      <Layout
        headerTitle={post.title}
        generic
        breadcrumb={{ name: post.title }}
      >
        <SEO
          title={post.seo.title}
          image={post.avContent?.featureImage?.sourceUrl}
          description={post.seo.metaDesc || he.decode(post.excerpt)}
        />

        <OperatorDetailsPage
          title={post.title}
          address={addressString}
          venue={post.avContactInfo.venue}
          email={post.avContactInfo.email}
          region={post.avContactInfo.region}
          booking_url={post.avContactInfo.bookingUrl}
          phone={post.avContactInfo.phone}
          website={post.avContactInfo.website}
          openingHours={post.avContactInfo?.days}
          content={post.avOperatorDetails.text}
          orgName={post.avOperatorDetails.orgName}
          videos={post.avOperatorDetails.videos}
          widget={post.avOperatorDetails.widget}
          gallery={post.gallery}
          mapData={post.map}
          bookingPopup={post.avOperatorDetails.bookingPopup}
          categories={post.operatorTypes.nodes}
          databaseId={post.databaseId}
        />

        <RelatedArticles />
      </Layout>
    )
  }
}

OperatorTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default OperatorTemplate

export const pageQuery = graphql`
  query($id: String!) {
    operator: wpOperator(id: { eq: $id }) {
      databaseId

      title
      excerpt
      operatorTypes {
        nodes {
          name
          slug
        }
      }
      seo {
        title
        metaDesc
      }
      map {
        lat
        lng
      }
      avOperatorDetails {
        text
        orgName
        widget
        bookingPopup
        videos {
          url
        }
      }
      avContactInfo {
        venue
        address
        state
        postcode
        suburb
        phone
        bookingUrl
        email
        website
        days {
          day
          times
        }
        region {
          ... on WpRegion {
            title
            slug
            regionField {
              regionPage {
                ... on WpPage {
                  title
                  uri
                }
              }
            }
          }
        }
      }
      gallery {
        sourceUrl
        srcSet
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
    }
  }
`
